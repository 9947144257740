// Header Navigation
const mobileButton = document.querySelector(".mobile-button");
const headerNav = document.querySelector("header .main-nav");
const contactButtons = document.querySelectorAll(".js-contact");
const contactDialog = document.querySelector("#contactDialog");
const closeDialogButton = contactDialog.querySelector("#closeDialog");

let mobileMq = window.matchMedia('(max-width:950px)');

if(mobileButton) {
    mobileButton.addEventListener("click", function() {
        headerNav.classList.toggle("mobile-open");
    })
}

window.addEventListener('resize', function() {
    if(!mobileMq.matches && headerNav.classList.contains("mobile-open")) {
        headerNav.classList.remove("mobile-open");
    }
});

contactButtons.forEach(button => {
    button.addEventListener('click', function() {
        if(contactDialog.classList.contains("hidden")) {
            contactDialog.classList.remove("hidden");
            contactDialog.classList.add("show");
        }
    })
});

closeDialogButton.addEventListener('click', function() {
    contactDialog.classList.add("hidden");
    contactDialog.classList.remove("show");
});

// Sort Toggle
const sortToggle = document.querySelector("button.sort-toggle");
const categories = document.querySelector(".js-categories");

if(sortToggle) {
    sortToggle.addEventListener("click", function() {
        categories.classList.toggle("show");
        sortToggle.classList.toggle("active");
    })
}

// Slider
const mediaLinksSlider = document.querySelectorAll(".slider-media_links");
const testimonialSlider = document.querySelectorAll(".slider-testimonial");
const postSlider = document.querySelectorAll(".slider-posts");
const favoritesSlider = document.querySelectorAll(".slider-favorites");

const linkSliderOptions = {
    accessibility: true,
    cellAlign: 'left',
    autoPlay: false,
    wrapAround: true,
    draggable: true,
    adaptiveHeight: false,
    resize: true,
    groupCells: true,
}

postSlider.forEach(slider => {
    var flkty = new Flickity(slider, {
        accessibility: true,
        cellAlign: 'left',
        wrapAround: true,
        adaptiveHeight: false,
        draggable: true,
    })
})

testimonialSlider.forEach(slider => {
    new Flickity(slider, {
        accessibility: true,
        cellAlign: 'center',
        autoPlay: 10000,
        wrapAround: true,
        adaptiveHeight: false,
        draggable: false,
        prevNextButtons: false
    })
})

mediaLinksSlider.forEach(slider => {
    const flkty = new Flickity(slider, linkSliderOptions);
    setTimeout(function () {   
        flkty.resize();        
    }, 500);
});

favoritesSlider.forEach(slider => {
    var flkty = new Flickity(slider, {
        accessibility: true,
        cellAlign: 'center',
        wrapAround: true,
        adaptiveHeight: false,
        draggable: true,
    })
})

// Tip Toggle
const tipSection = document.querySelector("section.tips-archive");

if(tipSection) {
    const tipButtons = document.querySelectorAll(".tip-toggle");
    const tipWrappers = document.querySelectorAll(".tips-slider-wrapper");
    const tipSliders = document.querySelectorAll(".slider-tips");

    const firstButton = tipButtons[0];
    const firstWrapper = tipWrappers[0];

    firstButton.classList.add("active");
    firstWrapper.classList.add("active");

    tipSliders.forEach(slider => {
        const flkty = new Flickity( slider, linkSliderOptions );

        const toggleTip = function(e) {
            const target = e.target;
            const buttonId = target.dataset.cat;
            tipButtons.forEach(button => {
                button.classList.remove("active");
            })
            tipWrappers.forEach(slider => {
                const sliderId = slider.dataset.cat;
                slider.classList.remove("active");
                if(sliderId === buttonId) {
                    slider.classList.add("active");
                    flkty.resize(); 
                }
            })
            target.classList.add("active");
        }
    
        tipButtons.forEach(button => {
            button.addEventListener("click", function(e) {
                toggleTip(e);
            })
        })
    })
}